import React from "react"
import { Link, navigate } from "gatsby"

import { handleLogin } from "../../../services/auth"


class Login extends React.Component {
  state = {
    email: ``,
    password: ``,
  }

  handleUpdate = event => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  handleSubmit = event => {
    event.preventDefault()
    handleLogin(this.state)
    .then((response) => {
      navigate(`/account`)
    }).catch(function(error) {
      alert(error.message)
      return false;
    })
  }

  resetPasswordHandler = () => {
    navigate(`/account/reset-password`)
  }

  render() {
    return (
      <>
        <h3>Log in</h3>
        <p>Don't have an account yet? <Link to="/account/signup">Click here to sign up and start promoting your kite school</Link>.</p>
        <form
          method="post"
          onSubmit={event => {
            this.handleSubmit(event)
          }}
        >
          <div className="form-group">
            <label htmlFor="email">Email Address</label>
            <input type="text" name="email" onChange={this.handleUpdate} />  
          </div>
          
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              name="password"
              onChange={this.handleUpdate}
            />
          </div>

          <button type="submit">Log in</button>
          <button type="button" onClick={this.resetPasswordHandler}>Reset password</button>
        </form>
      </>
    )
  }
}

export default Login
