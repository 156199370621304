import React from "react"
import { Link } from "gatsby"

import { resetPassword } from "../../../services/auth"


class PasswordReset extends React.Component {
  state = {
    email: ``,
    hasResetPassword: false
  }

  handleUpdate = event => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  handleSubmit = event => {
    event.preventDefault()
    resetPassword(this.state.email)
    .then((response) => {
      this.setState({hasResetPassword: true})
      alert('An email has been sent containing instructions to reset the password.')
    }).catch(function(error) {
      alert(error.message)
      return false;
    })
  }

  resetFormElement = (
    <form
      method="post"
      onSubmit={event => {
        this.handleSubmit(event)
      }}
    >
      <div className="form-group">
        <label htmlFor="email">Email Address</label>
        <input type="text" name="email" onChange={this.handleUpdate} />  
      </div>

      <button type="submit">Reset</button>
    </form>
  )

  render() {
    return (
      <>
        <h3>Reset password</h3>
        <p>Enter your email in the form below to reset your password. You will be sent an email containing instructions for resetting the password. Didn't mean to do that? <Link to="/account/login">Click here to log in instead</Link>.</p>
        {this.state.hasResetPassword ? <p>An email has been sent containing password reset instructions.</p> : this.resetFormElement}
      </>
    )
  }
}

export default PasswordReset
