import React from "react"
import { Link } from "gatsby"

import { user } from "../../../services/auth"


const AuthenticatedUserRoute = ({ component: Component, location, ...rest }) => {

  if (!user.uid && location.pathname !== `/account/login`) { // Do we need the check on the login route given this route doesn't protect that route anyway?
    return <p>You must be logged in to access this page. <Link to="/account/login">Click here to log in</Link>.</p>
  }

  return <Component {...rest} />
}

export default AuthenticatedUserRoute
