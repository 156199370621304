import React from 'react'
import { Link } from 'gatsby'

import { getCurrentUser, isBrowser, sendVerificationEmail } from '../../../services/auth'
import verifyStyles from './verifyAccount.module.css'


class VerifyAccount extends React.Component {

  state = { hasReset: false }
  

  sendVerificationEmailHandler () {
    sendVerificationEmail().then((response) => {
      this.hasResent = true
      this.setState({hasReset: true})
    }).catch(error => {
      alert('The email cannot be re-sent at the moment. Please try again later. If you have recently requested a verification email, be patient. It can take up to an hour to arrive!')
    })
  }

  preVerificationUI () {
    return <>
      <p>Thanks for signing up. We have sent you an email to confirm your account. Please follow the instructions in the email.</p>
      <p>Check your spam filters and junk mail folders if you can't find the email.</p>
      <p><strong>After verifying your email, refresh this page.</strong></p>
      <div className={verifyStyles.actionContainer}>
        {this.state.hasReset ? (<p>The verification email has been re-sent.</p>) : this.resendButton}
      </div>
    </>
  }

  postVerificationUI () {
    return <>
      <p>Thanks for signing up. You have successfully verified you email address and now have full access to manage your schools. <Link to="/account">Click here to go to your account</Link>.</p>
    </>
  }

  userHasVerifiedEmail () {
    if (!isBrowser()) {
      return false
    }
    const user = getCurrentUser()
    return user && user.emailVerified
  }

  hasResent = false;
  resendButton = <button className={verifyStyles.button} type="button" onClick={() => this.sendVerificationEmailHandler()}>Re-send verification email</button>

  render() {
    return (
      <>
        <h3>Verify your account credentials</h3>
        {this.userHasVerifiedEmail() ? this.postVerificationUI() : this.preVerificationUI()}
      </>
    )
  }
}

export default VerifyAccount
