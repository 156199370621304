import React from "react"
import { Link } from "gatsby"

import { user } from "../../../services/auth"

const VerifiedUserRoute = ({ component: Component, location, ...rest }) => {
  if (!user.uid) {
    return <p>You must be logged in to access this page. <Link to="/account/login">Click here to log in</Link>.</p>
  }
  if (!user.emailVerified) {
    return <p>You must verify your email address to access this page. <Link to="/account/verify">Click here to complete the account setup process</Link>.</p>
  }

  return <Component {...rest} />
}

export default VerifiedUserRoute
