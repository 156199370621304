import React from 'react'
import { Link } from 'gatsby'

import navbarStyles from './navbar.module.css'
import { logout } from '../../services/auth'


const Navbar = (props) => {

  const classNames = [navbarStyles.navbar]
  if (props.isLight) {
    classNames.push(navbarStyles.light)
  }

  const getAuthControls = () => {
    if (props.user && props.user.uid) {
      return <>
        <li>
          <Link to="/account" activeClassName={navbarStyles.active}>Account</Link>
        </li>
        <li>
          <a
            href="/"
            onClick={event => {
              event.preventDefault()
              logout().catch(error => alert(error.message))
            }}
          >Log out</a>
        </li>
      </>
    } else {
      return;
    }
  }

  return <nav className={classNames.join(' ')}>
    <ul>
      <li><Link to="/#faqs">FAQs</Link></li>
      <li><Link to="/#directory">Schools</Link></li>
      {getAuthControls()}
    </ul>
  </nav>
}

export default Navbar
