import React, { useEffect, useState } from 'react'
import { Router } from '@reach/router'
import { navigate } from 'gatsby'

import Layout from '../components/layout'
import Dashboard from './accountClientPages/Dashboard/Dashboard'
import Login from './accountClientPages/Login/Login'
import Signup from './accountClientPages/Signup/Signup'
import VerifyAccount from './accountClientPages/VerifyAccount/VerifyAccount'
import PasswordReset from './accountClientPages/PasswordReset/PasswordReset'
import VerifiedUserRoute from '../components/routeGuards/VerifiedUserRoute/VerifiedUserRoute'
import AuthenticatedUserRoute from '../components/routeGuards/AuthenticatedUserRoute/AuthenticatedUserRoute'
import Navbar from '../components/Navbar/Navbar'
import SEO from '../components/seo'
import { user$ } from '../services/auth'

const Account = () => {
  const [state, setState] = useState({
    user: {}
  })

  useEffect(() => {
    const subscription = user$.subscribe(authUser => {
      setState({user: authUser})
      if (!authUser.uid && window.location.pathname !== '/account/login' && window.location.pathname !== '/account/signup') {
        navigate('/account/login')
      } // HACK: It would be better to move this logic to AuthenticatedUserRoute.js because I need to separately keep a track of the route permissions here. But because the user is emitted asynchronously, I can't find a way to wait for the user before navigating.
      if (authUser.uid && !authUser.emailVerified && window.location.pathname !== '/account/login' && window.location.pathname !== '/account/signup' && window.location.pathname !== '/account/verify') {
        navigate('/account/verify')
      } // HACK: It would be better to move this logic to VerifiedUserRoute.js because I need to separately keep a track of the route permissions here. But because the user is emitted asynchronously, I can't find a way to wait for the user before navigating.
    })
    return () => {
      subscription.unsubscribe()
    }
  }, [state.user])

  return (
    <Layout>
      <SEO title={'Manage account'}></SEO>
      <Navbar user={state.user}></Navbar>
      <h2>Account</h2>
      <p>Manage your account, update and promote your school details here.</p>
      <Router basepath="/account">
        <VerifiedUserRoute path="/" component={Dashboard} />
        <Login path="login" />
        <Signup path="signup" />
        <PasswordReset path="reset-password" />
        <AuthenticatedUserRoute path="verify" component={VerifyAccount} />
      </Router>
    </Layout>
  )
}

export default Account
